
.img-404 {
  width: 200px;
  margin-bottom: 20px;
}

.text-404 {
  color: #6F766B;
  max-width: 500px;
  margin: 20px auto;
  padding: 0 20px;
}