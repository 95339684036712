
.block-gallery-section {
  padding: 20px 10px;
}

.block-gallery-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.block-image-cont {
  width: calc(25% - 45px);
  height: 270px;
  margin: 0 10px;
  position: relative;
}

  .block-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    transition: opacity 1s ease;
    transform-origin: bottom center;
  }

    .block-image.block-image-visible {
      opacity: 1;
      cursor: pointer;
    }

    .block-image.block-image-hidden {
      opacity: 0;
      pointer-events: none;
    }

.block-gallery-paging-cont {
  margin-top: 30px;
}

.paging-button {
  background-color: #EDDBD6;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

  .paging-button.active {
    background-color: #6F766B;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1050px) {
  .block-image-cont {
    width: calc(25% - 35px);
    height: 200px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 800px) {
  .block-gallery-cont button {
    display: none;
  }

  .block-image-cont {
    width: calc(50% - 10px);
    height: 200px;
    max-width: 300px;
    margin: 5px;
  }
}

@media screen and (max-width: 500px) {
  .block-image-cont {
    height: 150px;
  }
}