
.tab-gallery-cont {
  display: none;
  padding: 10px 0;
  border-top: none;
}

  .tab-gallery-cont.active {
    display: block;
  }

  .team-head-icon-cont {
    width: 85px;
    height: 85px;
    padding: 15px;
    border-radius: 50%;
    background-color: #C6D1C0;
    margin: 5px 0;
  }

  .team-head-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1200px) {
    .team-head-icon-cont {
      width: 65px;
      height: 65px;
    }
  }

  @media screen and (max-width: 400px) {
    .team-head-icon-cont {
      width: 50px;
      height: 50px;
      padding: 8px;
    }
  }