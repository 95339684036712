
.blog-previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px auto;
}

@media screen and (max-width: 800px) {
  .blog-previews {
    justify-content: space-between;
    padding: 0 20px;
  }
}