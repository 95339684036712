
.blog-preview {
  width: calc(33.333% - 30px);
  height: 450px;
  margin: 15px;
  position: relative;
}

  .blog-preview.featured {
    width: calc(66.666% - 30px);
  }

  .blog-preview.half {
    width: calc(50% - 30px);
  }

.blog-preview-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.blog-preview-content {
  display: block;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%);
  padding: 10px;
  text-align: left;
  color: #fff;
  font-size: 0.9em;
}

.blog-preview-head {
  margin: 0;
}

.blog-preview-date {
  font-weight: 600;
  font-size: 0.8em;
  margin: 10px 0 0 0;
}

.blog-preview-text {
  font-weight: 300;
  margin: 5px 0;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .blog-preview {
    height: 350px;
  }
}

@media screen and (max-width: 800px) {
  .blog-preview, .blog-preview.featured, .blog-preview.half {
    width: calc(50% - 10px);
    margin: 10px 0;
  }
}

@media screen and (max-width: 600px) {
  .blog-preview, .blog-preview.featured, .blog-preview.half {
    width: calc(100%);
  }
}

@media screen and (max-width: 600px) {
  .blog-preview {
    height: 270px;
  }
}