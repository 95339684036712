
.icon-group-section {
  padding: 20px;
}

.icon-group-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-item-cont {
  margin: 0;
}

.icon-item-img {
  background-color: #C6D1C0;
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.icon-item-label {
  margin: 5px 0 0 0;
  font-size: 1.2em;
  color: #6F766B;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .icon-item-img {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 900px) {
  .icon-group-cont {
    margin-bottom: 20px;
  }

  .icon-item-label {
    font-size: 1em;
    margin-top: 3px;
  }
}

@media screen and (max-width: 700px) {
  .icon-group-cont {
    flex-wrap: wrap;
  }

  .icon-item-cont {
    width: 33.333%;
    margin-bottom: 15px;
  }

  .icon-group-cont {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 400px) {
  .icon-item-img {
    width: 50px;
    height: 50px;
  }
}