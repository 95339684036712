
.green-block-section {
  position: relative;
  padding: 0 20px;
}

.green-block {
  position: relative;
  z-index: 2;
  color: #6F766B;
  border: 2px solid #6F766B;
}

.green-block-inner {
  padding: 40px;
  padding-top: 60px;
}

.three-columns {
  margin: 0;
  column-count: 3;
  column-gap: 40px;
}

.three-columns > * {
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  display: list-item;
  list-style-type: none;
  margin: 0 !important;
  padding-bottom: 0.5em;
}

.two-columns {
  margin: 0;
  column-count: 2;
  column-gap: 40px;
  padding-top: 30px;
}

.two-columns > * {
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  display: list-item;
  list-style-type: none;
  margin: 0 !important;
  padding-bottom: 0.5em;
}

.green-block-head-cont {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #fff;
  padding: 10px 40px;
}

  .green-block-head-cont h3 {
    margin: 0;
    font-size: 2em;
    white-space: nowrap;
  }

    .green-block-head-cont.with-sub-head h3 {
      font-size: 1.8em;
    }

  .green-block-head-cont p {
    margin: 0;
    color: #C6D1C0;
    font-size: 1.8em;
    font-family: Gilda;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .green-block-head-cont {
    padding: 0px 30px;
  }

  .green-block-head-cont h3 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 1000px) {
  .three-columns {
    column-count: 1;
    padding-top: 15px;
  }

  .two-columns {
    column-count: 1;
  }
}

@media screen and (max-width: 800px) {
  .green-block-head-cont {
    padding: 0px 20px;
  }

  .green-block-head-cont h3 {
    font-size: 1.5em;
  }

  .green-block-inner {
    padding: 30px 20px 20px 20px;
  }
}

@media screen and (max-width: 500px) {
  .green-block-inner {
    /* padding: 25px 10px 10px 10px; */
  }
}