
.specials-section {
  z-index: 2;
  position: relative;
  padding: 20px 10px;
  overflow: hidden;
}

.specials-list-cont {
  height: 200px;
  position: relative;
}

.special-cont {
  width: 42%;
  /* width: 91%; */
  height: 100%;
  border: 2px solid #6F766B;
  background-color: #e8ece6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  opacity: 1;
  left: 50%;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.2s ease-in;
}

  .special-open-quote {
    position: absolute;
    top: -10px;
    left: -20px;
    width: 60px;
    height: 60px;
    z-index: 1000;
  }

  .special-text {
    width: 90%;
    color: #6F766B;
    margin: 0;
    z-index: 1;
  }

  .special-cont.pos-0 {
    transform: translateX(-210%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

  .special-cont.pos-1 {
    transform: translateX(-160%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

  .special-cont.pos-2 {
    transform: translateX(-50%) scale(1.1);
    /* transform: translateX(-105%); */
    z-index: 2;
  }

  .special-cont.pos-3 {
    transform: translateX(60%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    /* transform: translateX(5%);
    z-index: 2; */
  }

  .special-cont.pos-4 {
    transform: translateX(110%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

.full-special-head-cont {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

  .full-special-head-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .full-special-head-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

.full-special-text {
  padding: 0 20px;
}

  .full-special-text p {
    text-align: left;
    font-weight: 300;
  }

  .full-special-text .special-full-name {
    font-size: 1.5em;
    font-family: Gilda;
    text-align: center;
    margin: 30px 0;
  }


/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1250px) {
  .special-cont {
    width: 89%;
  }
}

@media screen and (max-width: 1000px) {
  .full-special-head-cont {
    height: 300px;
  }

  .full-special-text .special-full-name {
    font-size: 1.2em;
    margin: 15px 0;
  }
}

@media screen and (max-width: 800px) {
  .specials-section {
    padding: 10px;
  }
  
  .specials-list-cont {
    height: 200px;
  }

  .special-cont {
    width: 88%;
  }

  .special-open-quote {
    position: absolute;
    top: -5px;
    left: -20px;
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .specials-list-cont {
    display: inline;
  }

  .special-cont {
    width: 90%;
    height: 180px;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 5%;
    margin-bottom: 10px;
  }

  .special-cont.pos-2 {
    transform: translateX(0);
  }

  .special-cont.pos-3 {
    transform: translateX(0);
    display: none;
  }

  .special-cont.pos-0 {
    display: none;
  }

  .special-cont.pos-1 {
    display: none;
  }

  .special-cont.pos-4 {
    display: none;
  }

  .full-special-head-cont {
    height: 200px;
  }

  .full-special-text p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .specials-list-cont {
    height: 200px;
  }

  .special-cont {
    width: 87%;
  }
}

@media screen and (max-width: 350px) {
  .special-cont {
    width: 86%;
  }
}