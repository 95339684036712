/*
* General styling to be used across the whole site
*/

@font-face {
  font-family: Gilda;
  src: url(../../../public/fonts/GildaDisplay-Regular.ttf);
}

/* Reseting box-sizing */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: Raleway;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

#root {
  height: calc(100% - 70px);
  margin-top: 70px;
  overflow-y: auto;
  position: relative;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Gilda;
  font-weight: 400;
}

.full-width {
  width: 100%;
}

.standard-box {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
  background-color: #fff;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.no-underline {
  text-decoration: none;
}

.relative {
  position: relative;
}

.standard-width {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  body {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 1050px) {
  #root {
    height: calc(100% - 55px);
    margin-top: 55px;
  }
}

@media screen and (max-width: 800px) {
  body {
    font-size: 0.8em;
  }
}