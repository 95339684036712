
.line-separator {
  height: 3px;
  width: 90%;
  max-width: 500px;
  background-color: #C6D1C0;
  margin: 20px auto;
  position: relative;
  z-index: 2;
}

.line-separator.invisible {
  visibility: hidden;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 900px) {
  .line-separator {
    margin: 20px auto;
  }
}