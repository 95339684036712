
.contact-blocks {
  padding: 40px 0 0 0;
}

.contact-block {
  margin: 10px 0 40px 0;
  width: calc(100%);
  border: 2px solid #6F766B;
  position: relative;
  color: #6F766B;
  padding-top: 10px;
}

.contact-block h3 {
  background-color: #fff;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 2em;
  white-space: nowrap;
}

.contact-block-contact {
  padding: 20px;
  font-weight: 300;
}

.contact-block-name {
  margin: 0 0 10px 0;
  font-weight: 400;
}

.contact-block-number {
  margin: 0 0 10px 0;
  white-space: pre-wrap;
}

.contact-block-email {
  margin: 0;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .contact-block h3 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 800px) {
  .contact-block h3 {
    font-size: 1.5em;
    padding: 0 15px;
  }
}

@media screen and (max-width: 800px) {
  .contact-block {
    margin: 10px 0 30px 0;
  }

  .contact-block-contact {
    padding: 10px;
  }
}