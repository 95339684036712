
.vid-section {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 20px;
}

.vid-cont {
  position: relative;
  height: 650px;
  border: 2px solid #C6D1C0;
}

  .vid-cont h3 {
    display: inline-block;
    background-color: #C6D1C0;
    padding: 10px 50px;
    font-size: 2.5em;
    font-weight: 200;
    color: #fff;
    margin: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    white-space: nowrap;
  }

  .vid-preview-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .vid-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .vid-cont h3 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 1000px) {
  .vid-cont {
    height: 500px;
  }
}

@media screen and (max-width: 800px) {
  .vid-cont h3 {
    font-size: 1.5em;
    padding: 10px 30px;
  }
}

@media screen and (max-width: 600px) {
  .vid-cont {
    height: 350px;
  }
}