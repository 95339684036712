
.header-section {
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 0;
}

  .header-section.flex-center {
    justify-content: center;
  }

.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header-logo {
  width: 70%;
  max-width: 600px;
  z-index: 1;
  border: 2px solid #fff;
  padding: 10px 20px;
}

  .header-line-to-bottom {
    width: 2px;
    height: calc(50% - 60px);
    z-index: 2;
    background-color: #fff;
  }

.header-sub-section {
  display: inline-block;
  background-color: #6F766B;
  color: #fff;
  position: relative;
  z-index: 2;
  font-size: 1.5em;
  width: 80%;
  max-width: 1000px;
  padding: 30px 50px;
  margin: 0;
  transform: translateY(-50%);
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .header-section {
    height: 500px;
  }

  .header-section-home-page .header-section {
    height: 800px !important;
    padding-top: 350px;
  }

  .header-section-home-page img {
    height: 800px !important;
  }

  .header-section-home-page .header-line-to-bottom {
    height: 600px;
  }
}