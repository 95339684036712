.gallery-block {
  width: 49%;
  display: inline-block;
  height: 300px;
  background-size: cover;
  background-position: bottom;
  margin: 0.5%;
  position: relative;
  cursor: pointer;
}

.gallery-block-long {
  width: 49%;
  display: inline-block;
  height: 650px;
  background-size: cover;
  background-position: bottom;
  margin: 0.5%;
  position: relative;
  cursor: pointer;
}

.gallery-block-full {
  width: 99%;
  display: inline-block;
  height: 600px;
  background-size: cover;
  background-position: bottom;
  margin: 0.5%;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .gallery-block {
    height: 250px;
  }

  .gallery-block-long {
    height: 550px;
  }

  .gallery-block-full {
    height: 450px;
  }
}

@media screen and (max-width: 700px) {
  .gallery-block-long {
    height: 500px;
  }
}

@media screen and (max-width: 600px) {
  .gallery-block {
    height: 200px;
  }

  .gallery-block-long {
    height: 400px;
  }

  .gallery-block-full {
    height: 350px;
  }
}

@media screen and (max-width: 450px) {
  .gallery-block {
    height: 170px;
  }

  .gallery-block-long {
    height: 300px;
  }

  .gallery-block-full {
    height: 250px;
  }
}