.menu-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #f5f5f5;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu-top-logo {
  width: 400px;
  z-index: 1;
  margin-left: 10px;
  padding-left: 0;
}

.menu-top-buttons {
  height: 100%;
}

  .menu-top-button {
    display: inline-block;
    width: 100px;
    height: 100%;
  }

    .menu-top-button.featured {
      width: 130px;
    }

.page-selected-cont {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

  .page-selected {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 60%;
    border-bottom: 2px solid #C6D1C0;
    border-top: 2px solid #C6D1C0;
  }

.nav-venues-dropdown {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-shadow: 0 0 25px 3px #000;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  transform: translateY(30px);
  pointer-events: none;
}

  .nav-venues-dropdown.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
    z-index: 5;
  }

.nav-venue-item {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

  .nav-venue-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.7;
  }

  .nav-venue-item h3 {
    z-index: 1;
    font-size: 2em;
    font-weight: 200;
    color: #fff;
    margin: 10px 0;
  }

.mobile-menu-toggle {
  display: none;
  margin-right: 10px;
}

.drawer-inner {
  width: 100%;
  padding: 50px 0 10px 0;
  background-color: #6F766B;
  position: relative;
  height: 100%;
}

.drawer-venues-dropdown {
  width: 100%;
  height: 170px;
  padding: 15px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #565c52;
}

.drawer-menu-top-logo {
  position: absolute;
  top: 20px;
  left: 10px;
  color: #fff;
  margin: 0;
  font-size: 1.5em;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1500px) {
  .menu-top-logo {
    width: 300px;
  }

  .menu-top-button {
    width: 85px;
  }

    .menu-top-button.featured {
      width: 110px;
    }

  .page-selected-cont {
    width: 85px;
  }
}

@media screen and (max-width: 1150px) {
  .menu-top-logo {
    width: 200px;
  }
}

@media screen and (max-width: 1050px) {
  .menu-top-buttons {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .menu-top {
    height: 55px;
  }

  .menu-top-logo {
    width: 280px;
  }

  .nav-venue-item h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 600px) {
  .drawer-venues-dropdown {
    height: 120px;
    padding: 10px 5px;
    gap: 5px;
  }
  
  .menu-top-logo {
    width: 200px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 400px) {
  .mobile-menu-toggle {
    margin-right: 5px;
  }

  .drawer-menu-top-logo {
    font-size: 1.2em;
  }
}