
.cf-gallery-section {
  padding: 0 10px 40px 10px;
  overflow: hidden;
}

.cf-gallery-cont {
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
  height: 450px;
  /* overflow-y: hidden; */
}

.cf-image-cont {
  height: 100%;
  width: calc(33.333% - 10px);
  position: absolute;
  top: 0;
  opacity: 1;
  left: 50%;
  transition: transform 0.4s ease-in-out, opacity 0.2s ease-in;
  cursor: pointer;
}

.cf-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

  .cf-image-cont.pos-0 {
    transform: translateX(-200%); /* -150 - 50 */
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

  .cf-image-cont.pos-1 {
    transform: translateX(-140%); /* -90 - 50 */
    z-index: 1;
  }

  .cf-image-cont.pos-2 {
    transform: translateX(-50%) scale(1.15); /* 0 - 50 */
    z-index: 2;
    box-shadow: 0 0 25px 3px #000;
  }

  .cf-image-cont.pos-3 {
    transform: translateX(40%); /* 90 - 50 */
    z-index: 1;
  }

  .cf-image-cont.pos-4 {
    transform: translateX(100%); /* 150 - 50 */
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

.cf-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 219, 214, 0.7);
  opacity: 1;
}

  .cf-image-cont.pos-2 .cf-image-overlay {
    opacity: 0;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .cf-gallery-cont {
    height: 350px;
  }
}

@media screen and (max-width: 800px) {
  .cf-gallery-section {
    padding: 0 10px 20px 10px;
  }

  .cf-image-cont {
    width: calc(100% - 120px);
  }
  
  .cf-gallery-cont {
    margin: 10px 0 20px 0;
  }

  .cf-image-cont.pos-1 {
    transform: translateX(-160%);
    opacity: 0;
  }

  .cf-image-cont.pos-2 {
    transform: translateX(-50%) scale(1);
    box-shadow: none;
  }

  .cf-image-cont.pos-3 {
    transform: translateX(60%);
    opacity: 0;
  }
}

@media screen and (max-width: 500px) {
  .cf-image-cont {
    width: calc(100% - 110px);
  }
}

@media screen and (max-width: 350px) {
  .tests-list-cont {
    height: 250px;
  }

  .test-cont {
    width: calc(100% - 110px);
  }

  .full-test-head-cont {
    height: 170px;
  }
}