
.venue-picker-section {
  padding: 80px 20px;
}

.venue-picker-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.venue-picker-cont a {
  flex: 1;
}

.venue-picker-item {
  position: relative;
  height: 400px;
}

  .venue-picker-item img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
  }

  .venue-picker-item h3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px 30px 10px;
    margin: 0;
    z-index: 2;
    color: #fff;
    font-size: 2em;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0) 100%);
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .venue-picker-item h3 {
    font-size: 1.8em;
  }
}


@media screen and (max-width: 1000px) {
  .venue-picker-item {
    height: 300px;
  }
}

@media screen and (max-width: 800px) {
  .venue-picker-item h3 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 700px) {
  .venue-picker-cont {
    flex-wrap: wrap;
  }

  .venue-picker-cont a {
    width: 100%;
    flex: auto;
  }

  .venue-picker-section {
    padding: 50px 20px;
  }
}

@media screen and (max-width: 400px) {
  .venue-picker-item {
    height: 250px;
  }
}