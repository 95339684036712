
.home-follow-button {
  font-size: 1em;
  width: 200px;
  height: 50px;
  align-items: center;
  margin: 10px auto;
  margin-bottom: 25px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1050px) {
  .home-follow-button {
    font-size: 0.8em;
    width: 170px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .home-follow-button {
    font-size: 0.7em;
    width: 150px;
    height: 35px;
  }
}