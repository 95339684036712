
.maps-cont {
  display: flex;
  flex-direction: row;
}

.map-cont {
  flex: 1;
  padding: 10px 20px;
}

  .map-cont p {
    margin-top: 0;
    font-size: 1.2em;
  }

  .map-cont iframe {
    width: 100%;
    height: 400px;
    border: 1px solid #666B4D;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 800px) {
  .map-cont p {
    font-size: 1em;
  }
}

@media screen and (max-width: 600px) {
  .map-cont {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 500px) {
  .map-cont {
    padding: 5px 0 0 0;
  }

  .map-cont iframe {
    height: 300px;
  }
}