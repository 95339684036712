
.gallery-section {
  padding: 0 20px;
}

.gal-outer-cont {
  margin-top: 50px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 400px) {
  .gallery-section {
    padding: 0 10px;
    font-size: 0.9em;
  }
}