
.contact-section {
  padding-bottom: 10px;
}

.contact-cont {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1050px) {
  .contact-section {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .contact-cont {
    max-width: 400px;
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .contact-section {
    padding-bottom: 30px;
  }
}