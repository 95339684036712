
.tests-section {
  z-index: 2;
  position: relative;
  padding: 40px 10px;
  overflow: hidden;
}

.tests-list-cont {
  height: 250px;
  position: relative;
}

.test-cont {
  width: calc(33.333% - 60px);
  height: 100%;
  border: 2px solid #6F766B;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  opacity: 1;
  left: 50%;
  transition: transform 0.4s ease-in-out, opacity 0.2s ease-in;
}

  .test-open-quote {
    position: absolute;
    top: -30px;
    left: -10px;
    width: 60px;
    height: 60px;
    z-index: 1000;
  }

  .test-text {
    width: 90%;
    color: #6F766B;
    margin: 0;
    z-index: 1;
  }

  .test-cont.pos-0 {
    transform: translateX(-210%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

  .test-cont.pos-1 {
    transform: translateX(-160%);
    z-index: 1;
  }

  .test-cont.pos-2 {
    transform: translateX(-50%) scale(1.1);
    z-index: 2;
  }

  .test-cont.pos-3 {
    transform: translateX(60%);
    z-index: 1;
  }

  .test-cont.pos-4 {
    transform: translateX(110%);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  }

.full-test-head-cont {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

  .full-test-head-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .full-test-head-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

.full-test-text {
  padding: 0 20px;
}

  .full-test-text p {
    text-align: left;
    font-weight: 300;
  }

  .full-test-text .test-full-name {
    font-size: 1.5em;
    font-family: Gilda;
    text-align: center;
    margin: 30px 0;
  }


/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .full-test-head-cont {
    height: 300px;
  }

  .full-test-text .test-full-name {
    font-size: 1.2em;
    margin: 15px 0;
  }
}

@media screen and (max-width: 800px) {
  .tests-section {
    padding: 10px;
  }
  
  .tests-list-cont {
    height: 170px;
  }

  .test-cont {
    width: calc(100% - 120px);
  }

  .test-cont.pos-2 {
    transform: translateX(-50%) scale(1);
  }

  .test-cont.pos-1 {
    transform: translateX(-160%);
    opacity: 0;
  }

  .test-cont.pos-3 {
    transform: translateX(60%);
    opacity: 0;
  }

  .test-open-quote {
    position: absolute;
    top: -20px;
    left: -10px;
    width: 40px;
    height: 40px;
  }
}


@media screen and (max-width: 600px) {
  .full-test-head-cont {
    height: 200px;
  }

  .full-test-text p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .tests-list-cont {
    height: 200px;
  }
}

@media screen and (max-width: 350px) {
  .tests-list-cont {
    height: 250px;
  }

  .test-cont {
    width: calc(100% - 110px);
  }

  .full-test-head-cont {
    height: 170px;
  }
}