.banner-image {
  width: 85%;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .banner-image {
    width: 90%;
  }
}