
footer {
  background-color: #C6D1C0;
  font-weight: 300;
  color: #fff;
}

.footer-socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 20px 20px;
  width: 35%;
  margin: auto;
}

.footer-badges {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
  width: 35%;
  margin: auto;
}

.footer-social-icon {
  width: 80px;
  height: 80px;
  padding: 20px;
  object-fit: contain;
}

.footer-badge-icon {
  width: 150px;
  height: 150px;
  padding: 20px;
  object-fit: contain;
}

.footer-contact {
  width: 35%;
  margin: auto;
  padding: 15px;
  font-size: 1.5em;
  line-height: 1.3em;
  border: solid;
  border-width: 1px;
}

  .footer-contact a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

.footer-legal {
  margin: 0;
  padding: 10px 0;
  font-size: 0.8em;
  padding-bottom: 50px;
}

  .footer-legal a {
    color: #fff;
    text-decoration: underline;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1050px) {
  .footer-contact {
    width: 50%;
  }

  .footer-socials {
    width: 50%;
  }
  
  .footer-badges {
    width: 35%;
  }

  .footer-badge-icon {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-contact a {
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .footer-socials {
    /* justify-content: center; */
    width: 80%;
  }
  
  .footer-social-icon {
    width: 60px;
    height: 60px;
    padding: 10px;
  }

  .footer-contact {
    font-size: 1em;
    line-height: 1.5em;
    width: 80%;
  }
  
  .footer-badges {
    width: 100%;
    display: inline-block;
  }

  .footer-badge-icon {
    padding: 20px;
  }

  .footer-contact a {
    font-size: 12px;
  }
}