
.blog-post-section {
  padding: 50px 40px 10px 40px;
  font-weight: 300;
}

.blog-post-content {
  font-size: 1.2em;
  line-height: 1.3em;
}

  .blog-post-content a {
    color: #000;
    text-decoration: underline;
  }

.blog-date-posted {
  margin: 0 0 40px 0;
  font-style: italic;
}

.blog-more-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30px;
}

@media screen and (max-width: 800px) {
  .blog-post-section {
    padding: 40px 20px 10px 20px;
  }

  .blog-more-cont {
    padding: 0 20px;
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .blog-more-cont {
    gap: 0;
  }
}

@media screen and (max-width: 500px) {
  .blog-date-posted {
    margin: 0 0 30px 0;
  }

  .blog-post-section {
    padding: 40px 10px 10px 10px;
  }

  .blog-more-cont {
    padding: 0 10px;
  }
}