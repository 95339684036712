
.block-heading {
  display: inline-block;
  position: relative;
  z-index: 2;
  color: #fff;
  max-width: 650px;
  width: auto;
  padding: 30px;
  border: 2px solid #fff;
  margin: 0 auto;
  font-size: 2em;
}

.block-heading.max-width {
  width: 80%;
}

.block-heading.no-border {
  border: none;
  color: #6F766B;
  padding: 20px;
}

.block-heading.extra-vertical-padding {
  padding: 60px 30px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .block-heading {
    max-width: 550px;
    padding: 20px;
    font-size: 1.8em;
  }

    .block-heading.extra-vertical-padding {
      padding: 50px 20px;
    }
}

@media screen and (max-width: 800px) {
  .block-heading {
    max-width: 400px;
    font-size: 1.5em;
  }

    .block-heading.extra-vertical-padding {
      padding: 40px 20px;
    }
}

@media screen and (max-width: 450px) {
  .block-heading {
    padding: 10px;
  }

    .block-heading.extra-vertical-padding {
      padding: 30px 10px;
    }
}