
.market-intro-section {
  padding: 40px 0;
}

.market-details-block {
  padding: 20px 0;
}

.market-sub-head {
  margin: 0 0 10px 0;
  font-weight: 400;
}

.market-details {
  margin: 0 0 10px 0;
  font-weight: 300;
}