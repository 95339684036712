
.contact-blocks-section {
  padding: 0 20px;
}

.message-form {
  background-color: #6F766B;
  padding: 20px;
  color: #fff;
  margin-bottom: 50px;
}

.message-form h3 {
  margin: 0 0 20px 0;
  font-size: 2em;
}

.message-form-inner {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .message-form h3 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 800px) {
  .message-form h3 {
    font-size: 1.5em;
  }
}